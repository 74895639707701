.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 4vh;
}

.input {
  border: 1px solid var(--ion-border-color);
  border-radius: 34px;
  margin-right: 4px;
}

input {
  --placeholder-color: var(--ion-text-color);
}
