.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.button {
  height: 80px;
  width: 80px;
  align-self: end;
}
