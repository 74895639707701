.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.userPofile {
  display: flex;
}

.avatar {
  width: 60px;
  height: 60px;
  border: 1px solid black;
  border-radius: 100%;
  margin-right: 16px;
}

.userName {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 22px;
  color: var(--ion-text-color);
}

.role {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: var(--ion-color-medium);
}

@media screen and (max-width: 1224px) {
  .avatar {
    display: none;
  }
}