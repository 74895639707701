.header {
  font-size: 1.2rem;
  line-height: 48px;
}

.empty {
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.loading {
  display: flex;
  margin: auto;
  padding: 1rem;
}

.error {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--ion-color-danger);
}

.link {
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  color: var(--ion-text-color);
}
