.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2vh 16vw;
  height: 100%;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 4vh;
  color: var(--ion-text-color);
}

.desc {
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  color: var(--ion-text-color);
  margin-bottom: 3vh;
}

.formItem {
  margin-bottom: 1vh;
}

.label {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 22px;
}

.input {
  border: 1px solid var(--ion-border-color);
  max-width: 60%;
  max-height: 6vh;
  border-radius: 34px;
  padding: 12px 16px 12px 16px;
  margin-bottom: 1vh;
  margin-top: 1vh;
}

.button {
  width: 60%;
  border-radius: 34px;
  margin-top: 2vh;
}

.link {
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  color: var(--ion-link-color);
}

.link:hover {
  text-decoration: underline;
  color: var(--ion-color-success-shade);
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .input {
    max-width: 80%;
  }
  .button {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 2vh 6vw;
  }
  .title {
    font-size: 2rem;
    margin-bottom: 2vh;
  }
}
