.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 2vh;
}

.label {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 22px;
}

.formItem {
  margin-bottom: 1vh;
}

.input {
  border: 1px solid var(--ion-border-color);
  border-radius: 34px;
  margin-bottom: 1vh;
  margin-top: 1vh;
  --placeholder-color: var(--ion-text-color);
}
